import '../css/app.scss'

import 'lazysizes'

const init = (e) => {
  let bg = e.target.getAttribute('data-bg')
  if (bg) {
    e.target.style.backgroundImage = 'url(' + bg + ')'
  }
}

document.addEventListener('lazyloaded', init, false)
document.addEventListener('htmx:afterRequest', init, false)

